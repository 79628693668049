import React, { useRef } from 'react';
import * as Sentry from '@sentry/nextjs';
import copy from 'copy-to-clipboard';
import NextImage from 'next/image';
import toast from 'react-hot-toast';
import QRCode from 'react-qr-code';
import { getCoachFollowUrl, getPlayerFollowUrl } from 'constants/user';
import { CoachStatusEnum } from 'types/generated/client';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import BounceLogoSplashShadow from 'svg/BounceLogoSplashShadow';
import CloseIcon from 'svg/CloseIcon';
import Button from 'components/Button';
import Modal from 'components/modals/Modal';

interface Props {
  isOpen: boolean;
  closeModal: (value: boolean) => void;
  shareUrl: string;
  title: string;
  buttonText?: string;
}

const INVITE_BG_IMAGE = '/images/invite/invite-bg.png';
const QR_CODE_DISPLAY_SIZE = 144;
const QR_CODE_DOWNLOAD_SIZE = 2048;

const ModalShareProfile = ({ isOpen, closeModal, shareUrl, title }: Props) => {
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const { user } = useGetCurrentUser();
  const profileUrl =
    typeof window !== 'undefined' && user
      ? user.coachStatus === CoachStatusEnum.Active
        ? getCoachFollowUrl(user?.username || user?.id || '')
        : getPlayerFollowUrl(user?.username || user?.id || '')
      : '';

  const downloadQRCode = () => {
    if (!qrCodeRef.current) return;

    const svg = qrCodeRef.current.querySelector('svg');
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    canvas.width = QR_CODE_DOWNLOAD_SIZE;
    canvas.height = QR_CODE_DOWNLOAD_SIZE;

    img.onload = () => {
      if (!ctx) return;
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'qr-code.png';
      downloadLink.href = pngFile;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={() => closeModal(false)}
        classNameMaxWidth="md:max-w-[40rem] xs:max-w-md"
      >
        <main className="relative flex w-full flex-col">
          <div className="flex flex-row items-end justify-end">
            <button
              className="absolute right-3 top-2.5 p-0.5 text-color-text-lightmode-invert dark:text-color-text-darkmode-invert"
              type="button"
              onClick={() => closeModal(false)}
            >
              <CloseIcon className="h-6 w-6" />
            </button>
          </div>
          <header className="flex w-full flex-col items-center justify-center">
            {/* Mob */}
            <NextImage
              src={INVITE_BG_IMAGE}
              alt="invite-bg"
              className=" w-full bg-fixed object-cover object-center lg:hidden"
              loading="lazy"
              height={178}
              width={375}
            />
            {/* Desktop */}
            <NextImage
              src={INVITE_BG_IMAGE}
              alt="invite-bg"
              className="hidden h-[11.5rem] w-full bg-fixed object-cover object-center lg:block"
              loading="lazy"
              height={178}
              width={375}
            />

            <div
              className="absolute z-10 flex items-center justify-center rounded-full bg-color-bg-lightmode-primary text-center dark:bg-color-bg-darkmode-primary xs:top-[7.5rem] md:top-72 lg:top-[7.5rem]"
              aria-hidden="true"
            >
              <BounceLogoSplashShadow className="h-36 w-36 pt-6" />
            </div>
          </header>
          <div className="mt-[6.5rem] px-6">
            <div className="flex flex-col items-center justify-center gap-8">
              <div className="flex flex-col gap-3">
                <h3 className="typography-product-display text-center text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                  Share profile
                </h3>
              </div>
              <div className="mb-10">
                <QRCode value={profileUrl} size={QR_CODE_DISPLAY_SIZE} />
                <div className="hidden" ref={qrCodeRef}>
                  <QRCode value={profileUrl} size={QR_CODE_DOWNLOAD_SIZE} />
                </div>
              </div>
            </div>
            <div className="flex w-full items-center justify-center gap-4 xs:mb-14 xs:flex-col md:flex-row">
              <Button
                onClick={() => {
                  try {
                    copy(profileUrl);
                    toast.success('Share profile link copied');
                  } catch (error) {
                    Sentry.captureException(error);
                    toast.error('Could not copy');
                  }
                }}
                variant="primary"
                label="Copy link"
                sizeDesktop="lg"
                sizeMobile="md"
                className="xs:h-10 xs:w-full md:h-full md:w-44"
              />
              <Button
                onClick={downloadQRCode}
                variant="secondary"
                label="Download QR Code"
                sizeDesktop="lg"
                sizeMobile="md"
                className="xs:h-10 xs:w-full md:h-full md:w-44"
              />
            </div>
          </div>
        </main>
      </Modal>
    </>
  );
};

export default ModalShareProfile;
